import React, { Suspense, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import ProjectLayout from "../ProjectLayout";
import { getLocalStorageItem } from "../Utils";
import { Skeleton } from "antd";
// import { getSiteStatus } from "../ApiServices/Apifun";
// import { setIsDownTime } from "../Store/LudoSlice";

function ProtectedAuth() {
  const isAuthenticated = () => {
    const user = getLocalStorageItem("isLoggedIn");
    return user;
  };
  useEffect(() => {
    // getSiteStatus()
    //   .then((res) => {
    //     dispatch(setIsDownTime(res.data))
    //     if(res.data.Value){
    //       localStorage.clear()
    //     }
    //   })
    //   .catch((err) => console.log(err));
  }, []);

  return !isAuthenticated() ? (
    <>
      {" "}
      <Suspense
        fallback={
          <div className="p-4">
            <Skeleton active className="mt-2"/>
            <Skeleton active className="mt-2"/>
            <Skeleton active className="mt-2"/>
            <Skeleton active className="mt-2"/>
            <Skeleton active className="mt-2"/>
            <Skeleton active className="mt-2"/>

          </div>
        }
      >
        <ProjectLayout>
          <Outlet />
        </ProjectLayout>
      </Suspense>
    </>
  ) : (
    <>
      <Navigate to="/" replace />
    </>
  );
}
export default ProtectedAuth;
