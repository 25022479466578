import Logo from "./logo.png";
import Logo1 from "./logo1.png";

import Ludo1 from "./ludo1.png";
import Ludo2 from "./ludo2.png";
import Ludo3 from "./ludo3.png";
import Ludo4 from "./ludo4.png";
import Ludo5 from "./ludo5.png";
import Ludo6 from "./ludo6.png";
import Dice from "./game-history.png";
import gift from "./gift.png";
import coin from "./coin.webp";
import ProfileBg from "./profilebg.jpg";
import game from "./game.png";
import cash from "./cash.png";
import refer from "./refferal.png";
import user from "./user.png";
import upi from "./upi.png";
import maintainance from "./Maintenance.jpg";
import celebration from "./celebration.jpg";
import Success from "./success.png";
import Failed from "./failed.png";
import Pending from "./pending.png";

export const Images = {
  Logo,Logo1,
  Ludo1,
  Ludo2,
  Ludo3,
  Ludo4,
  Ludo5,
  Ludo6,
  Dice,
  gift,
  coin,
  ProfileBg,
  game,
  cash,
  refer,
  user,
  upi,
  maintainance,
  celebration,Success,Failed,Pending
};
