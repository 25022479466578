import { useEffect, useRef, useState } from "react";
import { Images } from "../Assets";
import { GiHamburgerMenu } from "react-icons/gi";
import SideBarDrawer from "./SideBardrawer";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getContactData,
  GetReferralDetail,
  getUserProfile,
  getWalletBal,
} from "../ApiServices/Apifun";
import { useDispatch, useSelector } from "react-redux";
import { setContacts, setUser, setWalletBal } from "../Store/LudoSlice";
import { dispatchtoast, getLocalStorageItem } from "../Utils";
import { io } from "socket.io-client";
// import EnterCodeModal from "./EnterCodeModal";
// import GetCodeModal from "./GetCodeModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IoHomeSharp } from "react-icons/io5";
import { FaDownload, FaWallet, FaWhatsapp } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { SocketURL } from "../ApiServices/Axios";
import { FloatButton } from "antd";

const ProjectLayout = ({ children }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const walletBal = useSelector((state) => state.ludo.walletBal);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const prevPathnameRef = useRef(location.pathname);
  const contacts = useSelector((state) => state.ludo.contacts);
  const [socket, setSocket] = useState(null);
  const [detail, setDetail] = useState(null);
  const getReferDetail = () => {
    GetReferralDetail()
      .then((res) => {
        if (res.status) {
          setDetail(res.data);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    let socketInstance;
    if (getLocalStorageItem("isLoggedIn")) {
      socketInstance = io(SocketURL, {
        auth: {
          token: getLocalStorageItem("access_token"),
        },
      }); // Replace with your server URL

      setSocket(socketInstance);
    }

    return () => {
      socketInstance?.disconnect();
    };
  }, []);
  useEffect(() => {
    // Listen for 'connect' event
    if (socket && getLocalStorageItem("isLoggedIn")) {
      socket.on("connection", () => {
        console.log("Socket connected:", socket.connected);
      });
      socket.on("bonus_penalty", (data) => {
        dispatchtoast(data.message);
      });
    }
  }, [socket]);
  const getWalletBalance = () => {
    getWalletBal()
      .then((res) => {
        if (res.status) {
          dispatch(setWalletBal(res.data));
        }
      })
      .catch((err) => console.log(err));
  };
  const UserProfile = () => {
    getUserProfile()
      .then((res) => {
        if (res.status) {
          dispatch(setUser(res.data));
        }
      })
      .catch((err) => console.log(err));
  };
  const ContactDetail = () => {
    getContactData()
      .then((res) => {
        if (res.status) {
          dispatch(setContacts(res.data));
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (getLocalStorageItem("isLoggedIn")) {
      // if (prevPathnameRef.current !== location.pathname) {
      UserProfile();
      getReferDetail();
    }
    // }
    prevPathnameRef.current = location.pathname;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    ContactDetail();
    let interval;
    if (getLocalStorageItem("isLoggedIn")) {
      interval = setInterval(() => {
        getWalletBalance();
      }, 3000);
    }
    return () => clearInterval(interval);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);
  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };
  const handleClick = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${
      contacts?.Mobile ?? 7877308438
    }`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <>
      {location.pathname.includes("home") ? (
        <>
          {
            <FloatButton
              description={<p className="text-white">Download</p>}
              icon={<FaDownload color="white" />}
              className="download"
              shape="square"
              style={{
                right: 24,
                width: 150,
                padding: 5,
                bottom: 60,
              }}
              onClick={() => handleInstallClick()}
            />
          }
          <FloatButton
            description={<p className="text-white">Whatsapp</p>}
            icon={<FaWhatsapp color="white" />}
            className="whatsapp"
            shape="square"
            style={{
              bottom: 115,
              width: 150,
            }}
            onClick={() => handleClick()}
          />
        </>
      ) : null}

      <div className="grid grid-cols-7 h-screen place-items-center">
        <div className="col-span-7 lg:col-span-3 h-screen border w-full">
          {!location.pathname.includes("refercode") &&
            location.pathname !== "/" && (
              <div className="flex p-4 shadow-lg shadow-gray-400 justify-between items-center">
                <div className="flex cursor-pointer justify-start items-center gap-4">
                  <GiHamburgerMenu
                    size={20}
                    onClick={() => setOpenDrawer(true)}
                    className="cursor-pointer"
                  />
                  <img
                    onClick={() => navigate("/home")}
                    src={Images.Logo1}
                    alt="logopk"
                    className="w-28"
                  />
                </div>
                <div className="flex justify-start items-center gap-1">
                  <button
                    onClick={() => navigate("/add-fund")}
                    className="bg-secondary p-2  text-white text-xs"
                  >
                    Cash: {walletBal?.Total_balance ?? "-"}
                  </button>
                  {/* <button
                    onClick={() => navigate("/withdrawal-option")}
                    className="bg-secondary p-2  text-white text-xs"
                  >
                    Withdraw.: {walletBal?.withdrawableBalance ?? "-"}
                  </button> */}
                  <button
                    onClick={() => navigate("/refer")}
                    className="bg-secondary p-2  text-white text-xs"
                  >
                    Referral: {detail?.Current_Earnings ?? 0}
                  </button>
                </div>
              </div>
            )}
          <div
            className={` relative ${
              location.pathname !== "/" ? "h-[80%]" : "h-[100%]"
            }  2xl:h-[80%] overflow-scroll  mt-6`}
          >
            <div className="mx-6 mb-16">{children}</div>
            {!location.pathname.includes("refercode") &&
              location.pathname !== "/" && (
                <div className=" fixed bottom-0 w-full lg:w-[42.8%]  flex justify-around bg-primary h-10 items-center">
                  <IoHomeSharp
                    className="cursor-pointer"
                    onClick={() => navigate("/home")}
                    color="white"
                    size={20}
                  />
                  <FaWallet
                    className="cursor-pointer"
                    onClick={() => navigate("/wallet")}
                    color="white"
                    size={20}
                  />{" "}
                  <FaUser
                    className="cursor-pointer"
                    onClick={() => navigate("/profile")}
                    color="white"
                    size={20}
                  />
                </div>
              )}
          </div>
        </div>
        <div className="col-span-4 hidden lg:block main-bg w-full h-screen border">
          <div className="flex flex-col justify-center items-center text-white h-full">
            <LazyLoadImage src={Images.Logo} alt="site-logo" className="w-28" />
            <p className="font-bold italic text-lg">PK LUDO WIN REAL CASH</p>
          </div>
        </div>
      </div>
      {openDrawer && (
        <SideBarDrawer open={openDrawer} setopen={setOpenDrawer} />
      )}
      {/* <EnterCodeModal
        detail={joinedDetail}
        setDetail={setJoinedDetail}
        open={openEnterCode}
        setOpen={setOpenEntercode}
      />
      <GetCodeModal
        detail={getCode}
        setDetail={setGetCode}
        open={openGetCode}
        setOpen={setOpenGetCode}
      /> */}
    </>
  );
};

export default ProjectLayout;
