import React, { useEffect, useRef, useState } from "react";
import { Images } from "../../Assets";
import { Button, Form, Input, Spin, Switch } from "antd";
import { dispatchtoast, mobilePattern, setLocalStorageItem } from "../../Utils";
import { useNavigate, useParams } from "react-router-dom";
import { ResendOtp, VerifyOtp, userLogin } from "../../ApiServices/Apifun";
import { useForm } from "antd/es/form/Form";

const Login = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const { form } = useForm();
  const formref = useRef();
  const [btnLoading, setBtnLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [verifyToken, setVerifyToken] = useState(null);
  const [referral, setReferral] = useState(false);
  const [mobile, setMobile] = useState(null);
  const handleLogin = (val) => {
    if (!verifyToken) {
      setBtnLoading(true);
      setMobile(val.mobile);
      userLogin(
        { mobile: val.mobile, referral_code: val.referral_code },
        `?referral=${referral}`
      )
        .then((res) => {
          if (res.status) {
            setVerifyToken(res.verificationToken);
            dispatchtoast(res.message);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setBtnLoading(false));
    } else {
      setBtnLoading(true);
      VerifyOtp({
        verificationToken: verifyToken,
        mobile: val.mobile,
        otp: val.otp,
      })
        .then((res) => {
          if (res.status) {
            setLocalStorageItem("isLoggedIn", true);
            setLocalStorageItem("access_token", res.access_token);
            setLocalStorageItem("refreshToken", res.referesh_token);
            setLocalStorageItem("user", res.user);
            navigate("/home");
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setBtnLoading(false));
    }
  };
  const handleResend = () => {
    setSpinner(true);
    ResendOtp({
      mobile: mobile,
      verificationToken: verifyToken,
    })
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          setVerifyToken(res.verificationToken);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setSpinner(false));
  };
  useEffect(() => {
    if (code !== undefined && code !== null) {
      setReferral(true);
      formref.current.setFieldValue("referral_code", code);
    }
    //eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Spin spinning={spinner}>
        <div className="flex m-auto border bg-blue-200  flex-col w-full justify-center items-center  mt-20 py-8">
          <img src={Images.Logo} alt="site-logo" className="w-28" />
          <p className="text-2xl font-bold">Sign In</p>
          <div className="w-9/12">
            <Form
              form={form}
              ref={formref}
              onFinish={(val) => handleLogin(val)}
              onFinishFailed={(err) => console.log(err)}
            >
              <div>
                <Form.Item
                  className="w-full"
                  label="Mobile"
                  name={"mobile"}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Mobile Number",
                    },
                    {
                      pattern: mobilePattern,
                      message: "Please Enter Valid Mobile Number",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Mobile Number"
                    className="text-xs border w-full rounded-none bg-inputBg    h-9 px-2"
                  />
                </Form.Item>
                <div className="flex justify-start items-start gap-5">
                  <p>Do you have any referral Code?</p>
                  <Switch
                    disabled={code}
                    value={referral}
                    onChange={(e) => setReferral(e)}
                  />
                </div>
                {verifyToken && (
                  <>
                    <Form.Item
                      className="w-full"
                      label="OTP"
                      name={"otp"}
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Please Enter OTP",
                        },
                      ]}
                    >
                      <Input
                        maxLength={6}
                        placeholder="Enter OTP"
                        className="text-xs border w-full rounded-none bg-inputBg    h-9 px-2"
                      />
                    </Form.Item>
                    <div className="flex justify-end items-center ">
                      <p
                        onClick={() => handleResend()}
                        className="text-red-600 cursor-pointer font-bold"
                      >
                        Resend OTP
                      </p>
                    </div>
                  </>
                )}

                {referral && (
                  <>
                    <Form.Item
                      className="w-full"
                      label="Referral Code"
                      name={"referral_code"}
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Referral Code",
                        },
                      ]}
                    >
                      <Input
                        disabled={code}
                        maxLength={8}
                        placeholder="Enter Referral Code"
                        className="text-xs border w-full rounded-none bg-inputBg    h-9 px-2"
                      />
                    </Form.Item>
                  </>
                )}
              </div>
              <Form.Item className="w-full">
                <Button
                  loading={btnLoading}
                  htmlType="submit"
                  style={{ color: "white" }}
                  className="bg-black w-full mt-2 h-14 rounded-none text-white focus:outline-none  hover:border-none hover:outline-none"
                >
                  {verifyToken ? "Login" : "Send OTP"}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <p className="text-xs text-gray-500 mt-4 font-semibold">
          By continuing I agree that PK Ludo may store and process my data in
          accordance with theTerms of Use,Privacy Policy and that I am 18 years
          or older. I am not playing from Assam, Odisha, Nagaland, Sikkim,
          Meghalaya, Andhra Pradesh, or Telangana.
        </p>
      </Spin>
    </>
  );
};

export default Login;
