import { HashRouter, useRoutes } from "react-router-dom";
import "./App.css";
import { PrivateAuth } from "./Routes/PrivateRoutes";
import { PublicRoutes } from "./Routes/PublicRoutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainerMsg } from "./Utils";
import { useEffect } from "react";
import { getSiteStatus } from "./ApiServices/Apifun";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "./Assets";
import { useDispatch, useSelector } from "react-redux";
import { setIsDownTime } from "./Store/LudoSlice";

function App() {
  const PkLudoRoute = [...PublicRoutes(), ...PrivateAuth()];
  const PkLudoRouteConfig = () => useRoutes(PkLudoRoute);
  const isDowntime = useSelector((state) => state.ludo.isDowntime);
  const dispatch = useDispatch();
  const getstatus = () => {
    getSiteStatus()
      .then((res) => {
        dispatch(setIsDownTime(res.data));
        if (res.data.Value) {
          localStorage.clear();
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getstatus();
    // if ("serviceWorker" in navigator) {
    //   window.addEventListener("load", () => {
    //     navigator.serviceWorker
    //       .register("/service-worker.js")
    //       .then((registration) => {
    //         console.log("SW registered: ", registration);
    //       })
    //       .catch((registrationError) => {
    //         console.log("SW registration failed: ", registrationError);
    //       });
    //   });
    // }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <HashRouter>
        {!isDowntime.Value ? (
          <PkLudoRouteConfig />
        ) : (
          <div className="flex flex-col justify-center items-center h-screen">
            <LazyLoadImage
              className="w-1/2"
              src={Images.maintainance}
              alt="mainatin"
            />
            <p className="mt-4 text-primary font-bold text-4xl">
              {isDowntime.publicMessage
                ? isDowntime.publicMessage
                : "Under Maintenance"}
            </p>
          </div>
        )}
      </HashRouter>
      <ToastContainerMsg />
    </>
  );
}

export default App;
