import { Drawer } from "antd";
import React, { useState } from "react";
import { Images } from "../Assets";
import { IoIosLogOut, IoMdClose } from "react-icons/io";
import { IoChevronForwardSharp } from "react-icons/io5";
import {
  FaBell,
  FaHistory,
  FaUser,
  FaUserFriends,
  FaWallet,
} from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { LuUserPlus } from "react-icons/lu";
import { MdContactSupport, MdPolicy } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { trimString } from "../Utils";
import { APIURL } from "../ApiServices/Axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Logout } from "../ApiServices/Apifun";

const SideBarDrawer = ({ open, setopen }) => {
  const [selected, setSelected] = useState(0);
  const userDetail = useSelector((state) => state.ludo.userDetail);
  const walletBal = useSelector((state) => state.ludo.walletBal);

  const navigate = useNavigate();
  const arr = [
    {
      name: "Profile",
      icon: <FaUser size={19} />,
      to: "/profile",
    },
    {
      name: "Wallet",
      icon: <FaWallet size={19} />,
      to: "/wallet",
    },
    {
      name: "Game History",
      to: "/game-history",
      icon: <FaHistory size={19} />,
    },
    {
      name: "Transaction History",
      to: "/transaction-history",
      icon: <GrTransaction size={19} />,
    },
    {
      name: "Refer & Earn",
      to: "/refer-earn",
      icon: <LuUserPlus size={19} />,
    },
    {
      name: "Refer History",
      to: "/refer-history",
      icon: <FaUserFriends size={19} />,
    },
    {
      name: "Notification",
      to: "/notification",
      icon: <FaBell size={19} />,
    },
    {
      name: "Support",
      to: "/support",
      icon: <MdContactSupport size={19} />,
    },
    
    {
      name: "All Policy",
      to: "/allpolicy",
      icon: <MdPolicy size={19} />,
    },
    {
      name: "Logout",
      to: "/",
      icon: <IoIosLogOut size={19} />,
    },
  ];
  return (
    <Drawer
      title=""
      closeIcon={<p>&nbsp;</p>}
      width={320}
      className="sidebar-drawer"
      placement="left"
      onClose={() => setopen(false)}
      open={open}
    >
      <div className="profilecardbg h-40  p-2 rounded-lg">
        <div className="flex justify-around items-center ">
          <div className="flex justify-start items-center gap-x-4">
            <LazyLoadImage
              // crossOrigin="anonymous"
              onError={(e) => {
                e.target.src = Images.user; // Set the source of the image element
                e.target.alt = "noImage"; // Set the alt text of the image element
              }}
              src={APIURL + userDetail?.user?.image}
              alt="user"
              className="w-12 h-12 rounded-full"
            />
            <p className="text-white text-lg capitalize font-semibold">
              {trimString(userDetail.user?.name ?? "--")}
            </p>
          </div>
          <div className="bg-[#ffffff26] rounded-lg p-1">
            <IoMdClose
              onClick={() => setopen(false)}
              className="text-white cursor-pointer"
              size={20}
            />
          </div>
        </div>
        <div className="p-2 mt-7 bg-[#ffffff26] rounded-lg">
          <p className="text-2xl text-white">{walletBal?.Total_balance} ₹</p>
          <div className="flex justify-around items-center mt-2 text-gray-300">
            <p className="cursor-pointer font-semibold">Wallet Balance</p>
            <p
              onClick={() => {
                navigate("/wallet");
                setopen(false);
              }}
              className="cursor-pointer font-semibold"
            >
              + Top Up
            </p>
          </div>
        </div>
      </div>
      <div className="mt-4">
        {arr.map((i, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                if (i.name === "Logout") {
              Logout().then(res=>{
                if(res.status){
                  localStorage.clear();
                  navigate(i.to);
                }
              }).catch(err=>console.log(err))
                } else {
                  setSelected(index);
                  setopen(false);
                  navigate(i.to);
                }
              }}
              className={`flex cursor-pointer ${
                selected === index ? "bg-[#ffffff26] px-2 rounded-lg" : ""
              } text-white justify-between mt-6  hover:bg-[#ffffff26] hover:rounded-lg h-11 hover:px-2 py-3 transition-all items-center`}
            >
              <div className="flex justify-start items-center gap-x-4">
                <p className="  rounded-full">{i.icon}</p>
                <p className="text-sm font-semibold ">{i.name}</p>
              </div>
              <p>
                <IoChevronForwardSharp color="gray" size={20} />
              </p>
            </div>
          );
        })}
      </div>
    </Drawer>
  );
};

export default SideBarDrawer;
