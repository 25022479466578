import React, { lazy } from "react";
import ProtectedAuth from "./PrivateAuth";
const Dashboard = lazy(() => import("../Views/Main/Dashboard/Dashboard"));
const FundRequest = lazy(() => import("../Views/Main/FundRequest/FundRequest"));
const GameHistory = lazy(() => import("../Views/Main/GameHistory/GameHistory"));
const Kyc = lazy(() => import("../Views/Main/Kyc/Kyc"));
const Notification = lazy(() =>
  import("../Views/Main/Notification/Notification")
);
const AddCash = lazy(() => import("../Views/Main/Others/AddCash"));
const BatPage = lazy(() => import("../Views/Main/Others/BatPage"));
const Refer = lazy(() => import("../Views/Main/Others/Refer"));
const ReferHistory = lazy(() => import("../Views/Main/Others/ReferHistory"));
const RoomCodePage = lazy(() => import("../Views/Main/Others/RoomCodePage"));
const TransactionHistory = lazy(() =>
  import("../Views/Main/Others/TransactionHistory")
);
const WithdrawalOptions = lazy(() =>
  import("../Views/Main/Others/WithdrawalOptions")
);
const EditProfile = lazy(() => import("../Views/Main/Profile/EditProfile"));
const Profile = lazy(() => import("../Views/Main/Profile/Profile"));
const ReferEarn = lazy(() => import("../Views/Main/ReferEarn/ReferEarn"));
const FundReqReport = lazy(() => import("../Views/Main/Report/FundReqReport"));
const WithdralReport = lazy(() =>
  import("../Views/Main/Report/WithdrawalReport")
);
const Support = lazy(() => import("../Views/Main/Support/Support"));
const Wallet = lazy(() => import("../Views/Main/Wallet/Wallet"));

const AboutUs = lazy(() => import("../Views/Main/AllPolicy/AboutUs"));
const Refund = lazy(() => import("../Views/Main/AllPolicy/Refund"));
const TermsandCon = lazy(() => import("../Views/Main/AllPolicy/TermsCon"));
const AllPolicy = lazy(() => import("../Views/Main/AllPolicy"));
const FundStatus = lazy(() =>
  import("../Views/Main/FundRequest/FundStatus")
);
const PrivacyPolicy = lazy(() =>
  import("../Views/Main/AllPolicy/PrivacyPolicy")
);

const Rules = lazy(() =>
  import("../Views/Main/AllPolicy/Rules")
);

export const PrivateAuth = () => {
  return [
    {
      element: <ProtectedAuth />,
      children: [
        {
          path: "/home",
          element: <Dashboard />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: "/wallet",
          element: <Wallet />,
        },
        {
          path: "/game-history",
          element: <GameHistory />,
        },
        {
          path: "/refer-earn",
          element: <ReferEarn />,
        },
        {
          path: "/support",
          element: <Support />,
        },
        {
          path: "/add-fund",
          element: <FundRequest />,
        },
        {
          path: "/add-fund/:status",
          element: <FundStatus />,
        },
        {
          path: "/withdrawal-option",
          element: <WithdrawalOptions />,
        },
        {
          path: "/withdrawal/net-banking",
          element: <AddCash />,
        },
        {
          path: "/withdrawal-upi",
          element: <AddCash />,
        },
        {
          path: "/transaction-history",
          element: <TransactionHistory />,
        },
        {
          path: "/refer-history",
          element: <ReferHistory />,
        },
        {
          path: "/ludo-bat",
          element: <BatPage />,
        },
        {
          path: "/room-code",
          element: <RoomCodePage />,
        },
        {
          path: "/refer",
          element: <Refer />,
        },
        {
          path: "/edit-profile",
          element: <EditProfile />,
        },
        {
          path: "/kyc",
          element: <Kyc />,
        },
        {
          path: "/notification",
          element: <Notification />,
        },
        {
          path: "/report/fund-req",
          element: <FundReqReport />,
        },
        {
          path: "/report/withdrawal-req",
          element: <WithdralReport />,
        },
        {
          path: "/allpolicy",
          element: <AllPolicy />,
        },
        {
          path: "/aboutUs",
          element: <AboutUs />,
        },
        {
          path: "/refund",
          element: <Refund />,
        },
        {
          path: "/terms&con",
          element: <TermsandCon />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/rules",
          element: <Rules />,
        },
      ],
    },
  ];
};
