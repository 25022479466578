
export const Authendpoint = {
  Login: "api/v1/user/user-login",
  logout: "api/v1/user/user-logout",
  resendOtp: "api/v1/user/resendOTP",
  verifyOtp: "api/v1/user/verifyOTP",
};
export const Globalendpoints = {
  tournaments: "api/v1/user/tournaments",
  walletBal: "api/v1/user/wallet-balance",
  userProfile: "api/v1/user/profile",
  userprofileCount:"api/v1/user/userprofileCount",
  updateProfile: "api/v1/user/update-profile",

  addFundReq: "api/v1/user/add-fund-request",
  getFundReqReport: "api/v1/user/fund-requests",
  withdrawalReq: "api/v1/user/add-withdraw-request",
  getWithdrawalReqReport: "api/v1/user/withdraw-requests",

  getWalletTxn: "api/v1/user/wallet-transactions",
  getReferHistory: "api/v1/user/referHistory",

  createbettle: "api/v1/user/add-battles",
  getBattle: "/api/v1/user/getbattles",
  getBattleById: "/api/v1/user/getbattleById/",

  joinbattle: "api/v1/user/battles",
  EntergameCode: "api/v1/user/getgamecode",
  userBattleHistory: "api/v1/user/userBattleHistory",
  BattleAction: "api/v1/user/takeBattleAction/",
  deleteBattle: "api/v1/user/deleteBattle",
  joinBattleRequest: "api/v1/user/joinBattleRequestAction",

  notifications: "api/v1/user/get-user-notification",
  getnews: "/api/v1/user/getNews",
  Dokyc: "api/v1/user/create-kyc-request",
  getReferralDetail: "api/v1/user/getRerferalDetails",
  referralAmountAction: "api/v1/user/reedeem_Referal_Amount",

  createOrder: "api/v1/user/createOrder",
  paymentCallback: "api/v1/user/paymentCallback",
  getShareLinks: `api/v1/user/userShareLinks/`,

  siteStatus:"api/v1/admin/siteStatus",
  manualPayStatus:"api/v1/admin/ManualPayStatus",
  makePayment:"api/v1/user/MakePayment",
  contactData:"/api/v1/user/Contectdata",
  getRules:"/api/v1/user/getRules"
};
