import { Delete, FormDataPost, FormDataPut, Get, Post, Put, getAPIUrl } from "./apiMethod";
import { Authendpoint, Globalendpoints } from "./global";

export const userLogin = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.Login, param);
  return Post(url, data, false);
};
export const Logout = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.logout, param);
  return Post(url, data);
};

export const ResendOtp = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.resendOtp, param);
  return Post(url, data, false);
};

export const VerifyOtp = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.verifyOtp, param);
  return Post(url, data, false);
};
export const getWalletBal = (param = "") => {
  const url = getAPIUrl(Globalendpoints.walletBal, param);
  return Get(url);
};
export const getTournamnets = (param = "") => {
  const url = getAPIUrl(Globalendpoints.tournaments, param);
  return Get(url);
};
export const getUserProfile = (param = "") => {
  const url = getAPIUrl(Globalendpoints.userProfile, param);
  return Get(url);
};
export const getUserProfileCount = (param = "") => {
  const url = getAPIUrl(Globalendpoints.userprofileCount, param);
  return Get(url);
};
export const updateProfile = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateProfile, param);
  return FormDataPost(url, data);
};

export const addWithdrawalRequest = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.withdrawalReq, param);
  return Post(url, data);
};
export const getFundRequestReport = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getFundReqReport, param);
  return Get(url);
};
export const addFundRequest = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.addFundReq, param);
  return FormDataPost(url,data);
};
export const getWithdrawalRequestReport = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getWithdrawalReqReport, param);
  return Get(url);
};
export const createBattle = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.createbettle, param);
  return Post(url, data);
};

export const getBattle = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getBattle, param);
  return Get(url);
};
export const getBattleById = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getBattleById, param);
  return Get(url);
};
export const JoinBattle = (param = "") => {
  const url = getAPIUrl(Globalendpoints.joinbattle, param);
  return Post(url);
};
export const BattleAction = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.BattleAction, param);
  return FormDataPut(url,data);
};

export const deleteBattle = (param = "") => {
  const url = getAPIUrl(Globalendpoints.deleteBattle, param);
  return Delete(url);
};
export const joinBattleRequest = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.joinBattleRequest, param);
  return Post(url,data);
};
export const BattleHistory = (param = "") => {
  const url = getAPIUrl(Globalendpoints.userBattleHistory, param);
  return Get(url);
};
export const GetGameCode = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.EntergameCode, param);
  return Post(url, data);
};
export const Dokyc = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.Dokyc, param);
  return FormDataPost(url, data);
};
export const GetWalletTxn = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getWalletTxn, param);
  return Get(url);
};
export const GetReferHistory = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getReferHistory, param);
  return Get(url);
};
export const GetNotification = (param = "") => {
  const url = getAPIUrl(Globalendpoints.notifications, param);
  return Get(url);
};
export const GetReferralDetail = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getReferralDetail, param);
  return Get(url);
};
export const GetNews = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getnews, param);
  return Get(url);
};
export const DoReferralAction = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.referralAmountAction, param);
  return Put(url,data);
};

export const createOrder = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.createOrder, param);
  return Post(url,data);
};

export const paymentCallback = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.paymentCallback, param);
  return Post(url,data);
};

export const getUserShareLinks = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getShareLinks, param);
  return Get(url);
};

export const getSiteStatus = (param = "") => {
  const url = getAPIUrl(Globalendpoints.siteStatus, param);
  return Get(url);
};
export const getManualPayStatus = (param = "") => {
  const url = getAPIUrl(Globalendpoints.manualPayStatus, param);
  return Get(url);
};

export const DoMakePayment = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.makePayment, param);
  return Post(url,data);
};
export const getContactData = (param = "") => {
  const url = getAPIUrl(Globalendpoints.contactData, param);
  return Get(url);
};
export const getRules = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getRules, param);
  return Get(url);
};