import { createSlice } from "@reduxjs/toolkit";
// import { getLocalStorageItem } from "../Utils";

const initialState = {
  userDetail: {},
  walletBal: null,
  isDowntime:false,
  contacts:null
};

export const ludoSlice = createSlice({
  name: "ludo",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.userDetail = action.payload;
    },
    setWalletBal: (state, action) => {
      state.walletBal = action.payload;
    },
    setIsDownTime: (state, action) => {
      state.isDowntime = action.payload;
    },
    setContacts: (state, action) => {
      state.contacts = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setWalletBal,setGameCode,setJoinedById,setCodeAdded,setIsDownTime,setContacts } = ludoSlice.actions;

export default ludoSlice.reducer;
